export const callbackTimes = {
  "10 AM to 11 AM": 10,
  "11 AM to 12 Noon": 11,
  "12 Noon to 1 PM": 12,
  "1 PM to 2 PM": 13,
  "2 PM to 3 PM": 14, 
  "3 PM to 4 PM": 15,
  "4 PM to 5 PM": 16,
  "5 PM to 6 PM": 17,
  "6 PM to 7 PM": 18,
}

export const getCallbackString = (callbackTimeVal) => {
  const callbackString = Object.keys(callbackTimes).find(key => callbackTimes[key] === Number(callbackTimeVal)) || ''
  return callbackString.replace('to', '&')
}