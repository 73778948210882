export function getPolicyName(sumInsured) {
  if (sumInsured > 500000000) {
    return 'Standard Fire & Special Perils (SFSP)'
  } else if (sumInsured >= 50000000 && sumInsured <= 500000000) {
    // 5 cr and 50 cr
    return 'Bharat Laghu Udyam Suraksha (BLUS)'
  }
  return 'Bharat Sookshma Udyam Suraksha (BSUS)'
}

export const getBlogLink = policyName => {
  if (policyName.includes('BSUS'))
    return "https://blog.verak.in/bsus-policy-coverage-explained-amp/"
  else if (policyName.includes('BLUS'))
    return "https://blog.verak.in/product-explainers-bharat-laghu-udyam-suraksha-blus-policy-amp/"
  else if (policyName.includes('SFSP'))
    return "https://blog.verak.in/product-explainers-standard-fire-and-special-perils-insurance-amp/"
}

export const checkIsAfterMidnight = () => {
  const currentHours = new Date().getHours()
  // 12am to 10am
  return currentHours >= 0 && currentHours <10
}