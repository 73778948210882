import { sendApiRequest } from "./apiUtils"

export const sendProspectingData = async (data) => {
  if (!data) return
  return await sendApiRequest('/public/lead-prospect', data, 'PUT')
}

export const sendLead = async (lead) => {
  if (!lead) return
  return await sendApiRequest('/public/lead', lead)
}

export const sendOtherValuePing = async (otheValueData) => {
  if (!otheValueData) return
  return await sendApiRequest('/public/other-value-ping', otheValueData)
}

export const getProvisionalQuote = async (provisionalQuoteData) => {
  if (!provisionalQuoteData) return
  return  await sendApiRequest('/public/provisional-quote', provisionalQuoteData)
}

export const scheduleCall = async (data) => {
  if (!data) return
  return sendApiRequest('/public/schedule-call', data)
}