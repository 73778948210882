import React from "react"
import styled from "styled-components"

import { Button } from "../core/Button"
import { Text } from "../core/commonExports"
import Select from "../core/Select"
import { InputContainer, InputLabel, mandatoryField } from "../LeadFormSteps/common"
import Modal, { CtaContainer, ModalHeaderContainer } from "../Modal"
import { Loader } from "../core/Loader"
import { mobileNumWithCountryCode } from "../LeadFormSteps/sharedUtils"
import { fieldWiseProspectingStates } from "../LeadFormSteps/sharedData"
import { scheduleCall, sendProspectingData } from "../../api/prospectingAPIs"
import { callbackTimes, getCallbackString } from "../LeadFormSteps/provisionalQuoteScreenUtils"
import checkOfficeHours from "../../utils/checkOfficeHours"
import { checkIsAfterMidnight } from "../LeadFormSteps/thankyouScreenUtils"
import { OnMobile } from "../DesktopMobileComps"

import CheckGIF from "../../assets/check.gif"

const screens = {
  callScheduleSelection: "call-schedule-selection", 
  callScheduleSuccess: "call-schedule-success"
}

const CallScheduleModal = ({ mobileNum, handleModalClose, handleClaimGST, askForGst = true, showAfterHoursMsg = true}) => {
  const [screen, setScreen] = React.useState(screens.callScheduleSelection)
  const [selectedCallbackTime, setSelectedCallbackTime] = React.useState('')
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const handleCallbackTimeSelection = (value) => {
    setSelectedCallbackTime(value)
  }

  const handleClaimGSTClick = () => {
    handleClaimGST()
    handleModalClose()
  }

  const handleCallbackTimeConfirmation = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    const prospectingData = {
      Phone: mobileNumWithCountryCode(mobileNum),
      Data: {},
      Status: fieldWiseProspectingStates.callSchedule
    }
    await sendProspectingData(prospectingData)
      
    const reqbody = {
      phone: mobileNumWithCountryCode(mobileNum),
      hour: selectedCallbackTime
    }
    await scheduleCall(reqbody)
    setIsSubmitting(false)
    setScreen(screens.callScheduleSuccess)
  }

  let content = (
    <form style={{textAlign: "center"}} onSubmit={handleCallbackTimeConfirmation}>
      <Text fontSize="20px" mfontSize="16px" lineHeight="24px" mlineHeight="24px" fontWeight="bold" color="#8668AD">
        Schedule A Call 📞
      </Text>
      <Text fontSize="18px" lineHeight="25px" mfontSize="14px" mlineHeight="17.64px" fontWeight="500" color="rgb(153, 153, 153)" desktopStyles={{margin: "12px 0"}} mobileStyles={{margin: "8px 0"}}>
        Our insurance advisor will guide you on next steps
      </Text>
      <InputContainer style={{margin: "1rem 0"}}>
        <InputLabel htmlFor="call-options" desktopStyles={{marginBottom: "8px"}} mobileStyles={{fontSize: "14px"}}>
          Select your appointment time {mandatoryField}
        </InputLabel>
        <div style={{background: "#fff"}}>
          <Select
            name="call-options"
            style={{ width: '100%', height: '48px', borderWidth: '0.5px' }}
            compulsory
            value={selectedCallbackTime}
            onChange={selected => handleCallbackTimeSelection(selected)}
            placeholder="Select option"
          >
            {Object.entries(callbackTimes).map(callbackTime => (
              <option value={callbackTime[1]} key={callbackTime[0]}>
                {callbackTime[0]}
              </option>
            ))}
          </Select>
        </div>
        <OnMobile>
          <div style={{marginBottom: "4.75rem"}} />
        </OnMobile>
        <CtaContainer>
          <StyledButton 
            disabled={!selectedCallbackTime} 
            label={<>&#10003;&nbsp;&nbsp;Book Slot</>} 
            uppercaseLabel={false} 
            onClick={handleCallbackTimeConfirmation} 
            color="#2A2B7F" 
            style={{margin: "8px auto"}}
          />
        </CtaContainer>
      </InputContainer>
    </form>
  )

  if (screen === screens.callScheduleSuccess) {
    const isOfficeHours = checkOfficeHours()
    const isAfterMidnight = checkIsAfterMidnight()
    const callbackTimeString = getCallbackString(selectedCallbackTime) || "10 AM & 7 PM"
    let advisorCallTime = `will call you tomorrow between ${callbackTimeString}`

    if (isOfficeHours) {
      const currentHours = new Date().getHours()
      if (selectedCallbackTime > currentHours) {
        advisorCallTime = `will call you today between ${callbackTimeString}`
      }
    } else if (isAfterMidnight) {
      advisorCallTime = `will call you today between ${callbackTimeString}`
    }
    content = (
      <>
        <div style={{width: "fit-content", margin: "auto", marginBottom: "1rem"}}>
          <img src={CheckGIF} alt="check" width={120} height={120} />
        </div>
        <Text fontSize="20px" mfontSize="16px" lineHeight="24px" mlineHeight="24px" fontWeight="bold" style={{ margin: "0", textAlign: "center"}} color="#4AA161">
          Call Scheduled <span style={{marginLeft: "0.25rem"}}>✅</span>
        </Text>
        <Text fontSize="18px" lineHeight="25px" mfontSize="14px" mlineHeight="17.64px" fontWeight="500" color="rgb(153, 153, 153)" style={{textAlign: "center"}} desktopStyles={{margin: "12px 0"}} mobileStyles={{margin: "8px 0"}}>
          Our insurance advisor {advisorCallTime} to explain the policy in detail & find the best plan for you.
        </Text>
        {askForGst && (
          <CtaContainer>
            <Button 
              onClick={handleClaimGSTClick}
              type="secondary"
              label="Claim Savings with GST"
              uppercaseLabel={false}
              style={{padding: "12px", borderRadius: "14px"}}
              mobileStyles={{height: "40px", padding: "12px 8px", fontSize: "12px"}}
            />
          </CtaContainer>
        )}
      </>
    )
  }

  if (isSubmitting) {
    content = (
      <div style={{margin: "auto"}}>
        <Text
          fontSize="24px"
          mfontSize="18px"
          color={"#4D4D4D"}
          fontWeight="bold"
          style={{ margin: "auto", marginBottom: '32px', textAlign: "center" }}
        >
          Scheduling Appointment...
        </Text>
        <Loader style={{margin: "auto", marginBottom: "2rem", width: "fit-content"}} />
      </div>
    )
  }

  const isOfficeHours = checkOfficeHours()
  const isAfterMidnight = checkIsAfterMidnight()
  let modalTitle = "Talk To our Advisors"
  if (showAfterHoursMsg && !isOfficeHours) {
    let officeOpensDay = "tomorrow"
    if (isAfterMidnight) {
      officeOpensDay = "today"
    }
    modalTitle = `Office will open ${officeOpensDay} at 9:30 AM`
  }

  return (
    <Modal mobileBottomAlign handleClose={handleModalClose}>
      <ModalHeaderContainer>
        <Text 
          fontSize="16px" 
          mfontSize="14px" 
          lineHeight="20px" 
          mlineHeight="17px" 
          fontWeight="bold" 
          color="#333333" 
          style={{margin: "0", textAlign: "left", flex: "1"}}
        >
          {modalTitle}
        </Text>
      </ModalHeaderContainer>
      <ModalBodyContainer>
        {content}
      </ModalBodyContainer>
    </Modal>
  )
}

const ModalBodyContainer = styled.div`
  padding: 24px 0;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding-bottom: 40px;
    min-height: 460px;
  }
`

const StyledButton = styled(Button)`
  font-size: 16px; 
  line-height: 20.16px; 
  font-family: Plus Jakarta Sans Regular;
  font-weight: 600;
  background: ${p => p.color || "#FF934F"};
  border-radius: 17px;
  width: fit-content;
  gap: 10px;
  @media screen and (max-width: 768px) {
    width: min(150px, 100%);
    font-size: 14px;
    padding: 12px;
    height: 48px;
  }
`

export default CallScheduleModal