const checkOfficeHours = () => {
    const currentHours = new Date().getHours()
    const currentMins = new Date().getMinutes()
    // 9:30am to 6:30pm
    if (currentHours === 9 && currentMins >= 30) {
        return true
    } else if (currentHours === 18 && currentMins <= 30) {
        return true
    }
    return currentHours >= 10 && currentHours <= 17  
}

export default checkOfficeHours